import React, { useState, useRef, useEffect, Component } from 'react';
import axios from 'axios';
import { DKSpinner, DKIcon, DKInput, DKIcons, DKSearchBar } from 'deskera-ui-library';
import ic_profile_pic from "../../assets/menu/ic_profile_pic.png";
import Product from "./import_data_from_other_platforms/QuickBooks/Product";
import ChartOfAccounts from "./import_data_from_other_platforms/QuickBooks/ChartOfAccounts";
import Contacts from "./import_data_from_other_platforms/QuickBooks/Contacts";
import Invoices from './import_data_from_other_platforms/QuickBooks/Invoices';
import Tenant from '../../services/Tenant';
// import UserManager from '../../managers/UserManager';
// import TenantManager from '../../managers/TenantManager';
import { TENANT, TenantService } from "../../services/TenantMangerService";
import Table from '../../services/Table';
import ApiConstants from '../../utils/ApiConstants';
import { v4 as uuidv4 } from 'uuid';
import MarkdownIt from 'markdown-it';
import io, { Socket } from 'socket.io-client';
import SpinnerText from '../common/SpinnerText';
import ChatHeader from './ChatHeader';
import ChatConversation from './ChatConversation';
import ChatInput from './ChatInput';
import ChatSidebar from './ChatSidebar';
import ChatSuggestions from './ChatSuggestions';
import renderGraph from '../../utils/GraphUtils';
import { getAllThreads, addNewMessage, createNewThread, getAnswer, updateThreadName } from '../../utils/ChatUtils';

const componentMap = {
    'import_data_from_other_platforms/quickbooks/product': <Product />,
    'import_data_from_other_platforms/quickbooks/chart of accounts': <ChartOfAccounts />,
    'import_data_from_other_platforms/quickbooks/contacts': <Contacts />,
    'import_data_from_other_platforms/quickbooks/invoices': <Invoices />,
}

const SOCKET_SERVER_URL = ApiConstants.URL.CHAT.BASE_CHAT;
export default function ChatBody({ loading, setLoading, chatLog, setChatLog, threads, setThreads, pageWidth, setPageWidth, sidebarIsActive, setSidebarIsActive, suggestion, setSuggestion, selectedThread, setSelectedThread, darkMode, toggleDarkMode }) {
    const [socket, setSocket] = useState(null);
    const [receivedData, setReceivedData] = useState("");
    const [finalData, setFinalData] = useState("");
  
    const [input, setInput] = useState("");
    const [disableSendBtn, setDisableSendBtn] = useState(true);

    // const [tenantDetails, setTenantDetails] = useState(TenantManager.getTenantDetails());       // ::Not Working
    const [tenantId, setTenantId] = useState(null);
    const [submitUserQuery, setSubmitUserQuery] = useState(false);
    const [chatRoom, setChatRoom] = useState();

    const chatLogRef = useRef(null);

    // useEffect(() => {
    //     console.log('threads:', threads)
    // }, [threads])

    // []
    useEffect(() => {
        
        // set tenantId
        Tenant.getTenantDetails().then((tenant) => {
            TenantService.getUserData().then((userData) => {
                Table.getTenantData().then((peopleTenant) => {
                    setTenantId(peopleTenant.tenant.tenantId);
                })
            })
        })
        
        // window 'resize' eventListener 
        window.addEventListener('resize', updatePageWidth);
        return () => {
            window.removeEventListener('resize', (updatePageWidth));
        };
    }, []);

    // useEffect(() => {
    //     console.log('selected thread name:', selectedThread.name);
    // }, [selectedThread])
    

    // tenantId
    useEffect(() => {
        if(tenantId) {
            // Get all threads
            getAllThreads(tenantId, setThreads);
        }
    }, [tenantId])

    // [pageWidth]
    useEffect(() => {
        pageWidth>=1300 ? setSidebarIsActive(true) : setSidebarIsActive(false);
    }, [pageWidth])

    // Update pageWidth when the window is resized
    const updatePageWidth = () => {
        setPageWidth(window.innerWidth);
    };

    // [disableSendBtn]
    useEffect(() => {
        if(suggestion.selected) {
            handleSubmit();
        }
    }, [disableSendBtn])


    // suggestion
    useEffect(() => {
        if(suggestion.selected) {
            setInput(suggestion.value);
        }
    }, [suggestion])

    // [input]
    useEffect(() => {
        input && input.length>0 ? setDisableSendBtn(false) : setDisableSendBtn(true);
    }, [input])

    // [chatLog]
    useEffect(() => {
        // console.log('chatLog:', chatLog);
        
        if(chatLog && chatLog.length>=1 && chatLog[chatLog.length-1].sender === 'user' && submitUserQuery) {
            setSubmitUserQuery(false);
            getAnswer(tenantId, ApiConstants, input, chatLog, setSuggestion, setLoading, componentMap, setChatLog, handleNewMessage, chatRoom);
        }

        // auto-scroll to bottom
        if (chatLogRef.current) {
            chatLogRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [chatLog])



    useEffect(() => {
        socket && socket.emit("join", {room: chatRoom});
    }, [chatRoom])
    // socket 
    useEffect(() => {
        // console.log("hello")
        const s = io(SOCKET_SERVER_URL);

        setSocket(s);
        s.on('connect', () => {
            console.log("socket connected");
            setChatRoom(uuidv4());
        });
        s.on('data_response', (data) => {
            const md = new MarkdownIt();
            const htmlMsg = md.render(data);
            setChatLog(prevChatLog => [
                ...(prevChatLog && prevChatLog[prevChatLog.length - 1] && prevChatLog[prevChatLog.length - 1].sender === 'assistant'
                    ? prevChatLog.slice(0, -1)
                    : prevChatLog),
                { 
                    sender: 'assistant', 
                    message: {
                        ...prevChatLog.message,
                        message: <div className={`markdown-msg`} dangerouslySetInnerHTML={{ __html: htmlMsg }} />
                    },
                    // message: <div className={`markdown-msg`} dangerouslySetInnerHTML={{ __html: htmlMsg }} />,
                }
            ]);
        });

        s.on('graph_insight', (data) => {
            const md = new MarkdownIt();
            const htmlMsg = md.render(data);
            setChatLog(prevChatLog => {
                const prevChat = prevChatLog[prevChatLog.length-1]
                return ([
                    ...prevChatLog.slice(0, -1),
                    { 
                        sender: 'assistant', 
                        message: {
                            ...prevChat.message,
                            graphContent: {
                                ...prevChat.message.graphContent,
                                insight: <div className={`markdown-msg`} dangerouslySetInnerHTML={{ __html: htmlMsg }} />
                            },
                        }
                    }
                ])
            });
        });

        
        s.on('graph_vals', (data) => {
            // const md = new MarkdownIt();
            // const htmlMsg = md.render(data);
            console.log('21graph_val:', data);
            setChatLog(prevChatLog => {
                const prevChat = prevChatLog[prevChatLog.length-1]
                return ([
                    ...prevChatLog.slice(0, -1),
                    { 
                        sender: 'assistant', 
                        message: {
                            ...prevChat.message,
                            graphContent: {
                                ...prevChat.message.graphContent,
                                graph_vals: renderGraph(data)       // data is an object with two fields
                            },
                        }
                    }
                ])
            });
        });
        
        s.on('graph_outro', (data) => {
            const md = new MarkdownIt();
            const htmlMsg = md.render(data);
            setChatLog(prevChatLog => {
                const prevChat = prevChatLog[prevChatLog.length-1]
                return ([
                    ...prevChatLog.slice(0, -1),
                    { 
                        sender: 'assistant', 
                        message: {
                            ...prevChat.message,
                            graphContent: {
                                ...prevChat.message.graphContent,
                                outro: <div className={`markdown-msg`} dangerouslySetInnerHTML={{ __html: htmlMsg }} />
                            },
                        }
                    }
                ])
            });
        })

        s.on('resp_progress', (data) => {
            // setLoading(false)
            // console.log("streaming response progress: ", data);
            const md = new MarkdownIt();
            const htmlMsg = md.render(data);
            setChatLog((prevChatLog) => [
                ...(prevChatLog.length>0 && ( prevChatLog[prevChatLog.length - 1].sender) === 'assistant'
                    ? prevChatLog.slice(0, -1)
                    : prevChatLog),
                {
                    sender: 'assistant',
                    message: {
                        message: <div><SpinnerText text={data} /></div>,
                    },
                },
            ]);
        });


        return () => {
            console.log("socket disconnected")
            s.disconnect();
        };
      }, []);

    // [loading]
    useEffect(() => {
        if (!loading) {
            // Automatically focus on the input element after assistant's response
            document.getElementById('chat-input').focus();
        }
    }, [loading]);

    // [selectedThread]
    useEffect(() => {
        // if user has submitted input, then only add into db
        // console.log('selected thread ID updated:', selectedThread);
        if(submitUserQuery) {
            // setSubmitUserQuery(false);
            async function addMessageInNewThread() {
                await handleNewMessage(input, 'user');
            }
            addMessageInNewThread();
        }
    }, [selectedThread])

    async function handleNewMessage(message, sender) {
        try {
            const res = await addNewMessage(selectedThread?.id, message, sender);
            if (res !== null) {
                if(sender==='user') {
                    setChatLog((prevChatLog) => [...prevChatLog, res]);
                    // make api call to get answer ([chatLog] useEffect)
                } else {
                    setChatLog((prevChatLog) => {
                        // return [...prevChatLog.slice(0, -1), JSON.stringify(res)];
                        return [...prevChatLog.slice(0, -1), res];
                    });
                }
            } else {
                console.error('Cannot retrieve messages');
            }
        } catch (error) {
            console.error('An error occurred while fetching messages:', error);
        }
    }
    
    async function handleSubmit(e) {
        e && e.preventDefault();
        setSubmitUserQuery(true);
        setSuggestion({selected: false, value: '', options: []});
        if(disableSendBtn) {
            // send button is disabled
            return;
        }
        setLoading(true);

        if(!selectedThread?.id) {
            // create new thread...
            // const tenantId = '650fe2d573dc2bba640ac0ec';
            try {
                const res = await createNewThread(tenantId);
                if (res) {
                    setThreads((prevState) => ([[res.thread.threadID, res.thread.threadName], ...prevState]));
                    setSelectedThread({id: res.thread.threadID, name: res.thread.threadName})
                    updateThreadName(res.thread.threadID, input, setThreads, setSelectedThread);
                } else {
                    console.error('Error! Cannot create new thread');
                }
            } catch (error) {
                console.error('An error occurred while creating new thread:', error);
            }
        } else {
            await handleNewMessage(input, 'user');
        }
        setInput("");
    }

    function handleChange(e) {
        setInput(e.target.value);
    }
    return (
        <div className={`main-container${darkMode ? '-dark' : ''} ${!sidebarIsActive && 'flex-1'}`} style={{fontFamily: 'Inter'}}>
            {/* <ChatSidebar setSuggestion={setSuggestion} loading={loading} setSelectedThreadName={setSelectedThreadName} selectedThreadId={selectedThreadId} setSelectedThreadId={setSelectedThreadId} setChatLog={setChatLog} threads={threads} darkMode={darkMode} pageWidth={pageWidth} sidebarIsActive={sidebarIsActive} setSidebarIsActive={setSidebarIsActive} /> */}
            <ChatHeader threads={threads} selectedThread={selectedThread} sidebarIsActive={sidebarIsActive} darkMode={darkMode} toggleDarkMode={toggleDarkMode} setSidebarIsActive={setSidebarIsActive} chatLog={chatLog} />
            <ChatConversation 
                threads={threads}
                selectedThread={selectedThread}
                chatLog={chatLog} 
                chatLogRef={chatLogRef}
                componentMap={componentMap}
                setInput={setInput}
                handleSubmit={handleSubmit}
                suggestion={suggestion}
                setSuggestion={setSuggestion}
                darkMode={darkMode}
            />
            <ChatInput 
                handleSubmit={handleSubmit} 
                input={input} 
                setInput={setInput} 
                loading={loading}
                handleChange={handleChange}
                darkMode={darkMode}
                disableSendBtn={disableSendBtn}
                chatLog={chatLog}
                setSuggestion={setSuggestion}
            />
        </div>
    )
}


