import React, { useEffect, useState } from 'react'

function Home({ loading, darkMode, setSuggestion }) {

  const [randomSuggestions, setRandomSuggestions] = useState([]);
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  useEffect(() => {
    // Function to randomly select n elements from an array
    function getRandomElementsFromArray(arr, n) {
      const shuffled = arr.sort(() => 0.5 - Math.random());
      return shuffled.slice(0, n);
    }

    const random = getRandomElementsFromArray(homeSuggestions, 4);
      setRandomSuggestions(random);
    }, [])

    useEffect(() => {
      console.log(randomSuggestions)
    }, [randomSuggestions])
    
  const assistantHelpMessages = [
    "Deskera AI Assistant",
    "Try checking your current cash balance",
    "Explore your cash flow and financial health",
    "Start analyzing recent cash flow trends",
    "Take a look at receivables for this month",
    "Review payables to plan your finances",
    // "Explore recent cash flow trends with me",
    // "Consider payables to plan your finances better.",
    // "Analyze inventory turnover for cost efficiency.",
    // "Evaluate key performance indicators for insights.",
    // "Examine recent sales trends to boost revenue.",
  ];

  useEffect(() => {
  const interval = setInterval(() => {
    setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % assistantHelpMessages.length);
  }, 4000);

    return () => clearInterval(interval);
  }, []);
  const homeSuggestions = [
    {"questionUserCanAsk": "Break down this month's P&L?", "description": "Detailed monthly P&L"},
    {"questionUserCanAsk": "Last quarter's cash flow trend?", "description": "Quarterly Cash Flow"},
    {"questionUserCanAsk": "Year-over-year P&L?", "description": "Annual P&L comparison"},
    {"questionUserCanAsk": "Quarterly bank reconciliation?", "description": "Quarterly Reconciliation"},
    {"questionUserCanAsk": "This year's balance sheet by quarter?", "description": "Quarterly Balance Sheet"},
    {"questionUserCanAsk": "Status of vendor payables?", "description": "Vendor Accounts"},
    {"questionUserCanAsk": "Fiscal year Trial Balance?", "description": "Annual Trial Balance"},
    {"questionUserCanAsk": "90-day aged receivables?", "description": "Aged Receivables"},
    {"questionUserCanAsk": "Sales segmented by product?", "description": "Sales By Product"}
  ]
  
  return (
      <div className={`home-container${darkMode ? '-dark' : ''}`}>
        <div className={`home-heading-container${darkMode ? '-dark' : ''}  p-s`}>
          <div className={`home-heading${darkMode ? '-dark' : ''} `}>
            {assistantHelpMessages.map((item, index) => (
              <React.Fragment key={index}>
              {/* <div className={`home-heading-item${darkMode ? '-dark' : ''}`}>{item}</div> */}
              <div className={`home-heading-item${darkMode ? '-dark' : ''}`}>Deskera AI Assistant</div>
              {index !== assistantHelpMessages.length - 1 && <br />/* Add a horizontal line if it's not the last item */}
            </React.Fragment>
            ))}
          </div>
        </div>
        <div className='home-suggestion-row-container'>
          <div className='home-suggestion-row'>
            <div className={`home-suggestion-item${darkMode ? '-dark' : ''} cursor-pointer`} onClick={() => setSuggestion((prevState) => ({...prevState, selected: true, value: randomSuggestions[0].questionUserCanAsk}))}><span className={`random-suggestion-title${darkMode ? '-dark' : ''}`}>{randomSuggestions[0] && randomSuggestions[0].description}</span><span className={`random-suggestion-question${darkMode ? '-dark' : ''}`}>{randomSuggestions[0] && randomSuggestions[0].questionUserCanAsk}</span></div>
            <div className={`home-suggestion-item${darkMode ? '-dark' : ''} cursor-pointer`} onClick={() => setSuggestion((prevState) => ({...prevState, selected: true, value: randomSuggestions[1].questionUserCanAsk}))}><span className={`random-suggestion-title${darkMode ? '-dark' : ''}`}>{randomSuggestions[1] && randomSuggestions[1].description}</span><span className={`random-suggestion-question${darkMode ? '-dark' : ''}`}>{randomSuggestions[1] && randomSuggestions[1].questionUserCanAsk}</span></div>
          </div>
          <div className='home-suggestion-row'>
            <div className={`home-suggestion-item${darkMode ? '-dark' : ''} cursor-pointer`} onClick={() => setSuggestion((prevState) => ({...prevState, selected: true, value: randomSuggestions[2].questionUserCanAsk}))}><span className={`random-suggestion-title${darkMode ? '-dark' : ''}`}>{randomSuggestions[2] && randomSuggestions[2].description}</span><span className={`random-suggestion-question${darkMode ? '-dark' : ''}`}>{randomSuggestions[2] && randomSuggestions[2].questionUserCanAsk}</span></div>
            <div className={`home-suggestion-item${darkMode ? '-dark' : ''} cursor-pointer`} onClick={() => setSuggestion((prevState) => ({...prevState, selected: true, value: randomSuggestions[3].questionUserCanAsk}))}><span className={`random-suggestion-title${darkMode ? '-dark' : ''}`}>{randomSuggestions[3] && randomSuggestions[3].description}</span><span className={`random-suggestion-question${darkMode ? '-dark' : ''}`}>{randomSuggestions[3] && randomSuggestions[3].questionUserCanAsk}</span></div>
          </div>
        </div>
    </div>
  )
}

export default Home
