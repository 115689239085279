import BarGraph from '../components/chatbot/graphs/BarGraph';

export default function renderGraph(data) {
  const graphType = data.type;
  const graphData = data.graph_vals;
  const toPredict = data.toPredict;
  console.log('data(graphUtils):', data);
  switch(graphType) {
    case 'bar':
      return <BarGraph data={graphData} toPredict={toPredict} />
    default:
      break;
  }
}