import menuDark from '../../assets/chatbot/hamburger_dark.svg';
import menuLight from '../../assets/chatbot/hamburger_light.svg';
import ToggleSwitch from './ToggleSwitch';

export default function ChatHeader({ selectedThread, chatLog, darkMode, toggleDarkMode, setSidebarIsActive, sidebarIsActive }) {
  

  return (
    <div className={`header${darkMode ? '-dark' : ''} d-flex row justify-content-center`}>
      {
        <img 
          className={`hamburger-menu${darkMode ? '-dark' : ''} ${sidebarIsActive ? 'isInactive' : 'isActive'} cursor-pointer`}
          onClick={() => setSidebarIsActive(true)}
          src={darkMode ? menuLight : menuDark} 
          alt="hamburger-menu" 
        />
      }
      {chatLog && chatLog.length>=1 && <div className={`chat-heading${darkMode ? '-dark' : ''} border-radius-m p-s mt-m d-flex justify-content-between align-items-center`} >💬 {selectedThread && selectedThread.name.replace(/"/g, '')}</div>}
      <div className='toggle-switch-container'><ToggleSwitch darkMode={darkMode} toggleDarkMode={toggleDarkMode} /></div>
    </div>
  )
}
