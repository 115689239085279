import { useEffect, useState } from 'react';
import ChatBody from './ChatBody';
import ChatSidebar from './ChatSidebar';

function MainBody() {
  const [darkMode, setDarkMode] = useState(localStorage.getItem('darkMode') === 'true');
  const [loading, setLoading] = useState(false);
  const [suggestion, setSuggestion] = useState({selected: false, value: '', options: []});
  const [selectedThread, setSelectedThread] = useState();
  const [threads, setThreads] = useState([]);
  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const [sidebarIsActive, setSidebarIsActive] = useState(pageWidth>=1300 ? true : false);
  const [chatLog, setChatLog] = useState([]);

  useEffect(() => {
    console.log(selectedThread);
  }, [selectedThread])
  
  useEffect(() => {
    localStorage.setItem('darkMode', JSON.stringify(darkMode));
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode(prevDarkMode => !prevDarkMode);
  };
  
  return (
    <div className={`main-body${darkMode ? '-dark' : ''} d-flex  parent-height parent-width`}>
      <div className={`left-menu${darkMode ? '-dark' : ''}`}>
        <ChatSidebar setSuggestion={setSuggestion} loading={loading} selectedThread={selectedThread} setSelectedThread={setSelectedThread} setChatLog={setChatLog} threads={threads} darkMode={darkMode} pageWidth={pageWidth} sidebarIsActive={sidebarIsActive} setSidebarIsActive={setSidebarIsActive} />
      </div>
      <ChatBody
        loading={loading}
        setLoading={setLoading}
        chatLog={chatLog}
        setChatLog={setChatLog}
        threads={threads}
        setThreads={setThreads}
        pageWidth={pageWidth}
        setPageWidth={setPageWidth}
        sidebarIsActive={sidebarIsActive}
        setSidebarIsActive={setSidebarIsActive}
        suggestion={suggestion}
        setSuggestion={setSuggestion}
        selectedThread={selectedThread}
        setSelectedThread={setSelectedThread}
        darkMode={darkMode}
        toggleDarkMode={toggleDarkMode}
      />

      {/* <ChatBody
        loading={loading}
        setLoading={setLoading}
        chatLog={chatLog}
        setChatLog={setChatLog}
        threads={threads}
        setThreads={setThreads}
        pageWidth={pageWidth}
        setPageWidth={setPageWidth}
        sidebarIsActive={sidebarIsActive}
        setSidebarIsActive={setSidebarIsActive}
        suggestion={suggestion}
        setSuggestion={setSuggestion}
        selectedThreadId={selectedThreadId}
        setSelectedThreadId={setSelectedThreadId}
        darkMode={darkMode}
        toggleDarkMode={toggleDarkMode}
        selectedThreadName={selectedThreadName}
        setSelectedThreadName={setSelectedThreadName}
      /> */}

    </div>
  )
}

export default MainBody
