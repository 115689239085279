import { useEffect } from 'react';

export default function ToggleSwitch({ darkMode, toggleDarkMode }) {
  useEffect(() => {
    console.log('darkMode:', darkMode);
  }, [darkMode])
  return (
    <label className='switch'>
      <input type='checkbox' onClick={toggleDarkMode} defaultChecked={darkMode} />
      <span className={`slider cursor-pointer`} />
    </label>
  )
}
