import axios from 'axios';
// import TenantManager from '../managers/TenantManager';
import ApiConstants from './ApiConstants';

export function getAllThreads(tenantId, setThreads) {
  try {
    axios.get(`${ApiConstants.URL.CHAT.BASE_CHAT}/chat/threads/${tenantId}`)
    .then(response => {
        setThreads(response.data.reverse());
    })
    .catch(error => {
        console.error('Error:', error);
    });
  } catch(err) {
      console.error('error fetching threads:', err);
  }
}

export async function getMessagesByThreadId(threadId) {
  try {
    const response = await axios.get(`${ApiConstants.URL.CHAT.BASE_CHAT}/chat/messages/${threadId}`);
    
    if (response.status === 200) {
      const messages = response.data;
      return messages;
    } else {
      console.error(`get messages by thread_id request failed with status: ${response.status}`);
      return null;
    }
  } catch (error) {
    console.error('An error occurred while fetching messages:', error);
    return null;
  }
}

export async function addNewMessage(threadId, message, sender) {
  try {
    const response = await axios.post(`${ApiConstants.URL.CHAT.BASE_CHAT}/chat/messages`, 
      {
        "threadID": threadId,
        "sender": sender,
        "message": message
      }
    );
    
    if (response.status === 200) {
      const data = response.data;
      return data.message;
    } else {
      console.error(`add message request failed with status: ${response.status}`);
      return null;
    }
  } catch (error) {
    console.error('An error occurred while adding message:', error);
    return null;
  }
}

export async function createNewThread(tenantId) {
  const userID = tenantId;
  try {
    const response = await axios.post(`${ApiConstants.URL.CHAT.BASE_CHAT}/chat/threads`, {
        userID
      }
    );
    
    if (response.status === 200) {
      const data = response.data;
      console.log('new thread created!', data);
      return data;
    } else {
      console.error(`create new thread request failed with status: ${response.status}`);
      return null;
    }
  } catch (error) {
    console.error('An error occurred while creating new thread:', error);
    return null;
  }
}

export function getAnswer(tenantId, ApiConstants, input, chatLog, setSuggestion, setLoading, componentMap, setChatLog, handleNewMessage, chatRoom) {
  // make api call to get answer
  axios.post(`${ApiConstants.URL.CHAT.BASE_CHAT}/${ApiConstants.URL.CHAT.ANSWER}`, {
    room: chatRoom,
    user_message: input,
    // messages: chatLog.length===0 ? [{sender: 'user', message: input}] : [...chatLog.slice(-2), { sender: 'user', message: input }],
    messages: chatLog.length===0 ? [{sender: 'user', message: input}] : [...chatLog.slice(-2)],
    // tenant_id: TenantManager.getTenantDetails().id
    tenant_id: tenantId
  }, {
      headers: {
        "x-access-token": ApiConstants.ACCESS_TOKEN,
      },
    })
  .then((response) => {
      // if(response.status>200)
      try{
        console.log('api \'response\':', response);
        var msg = response.data.message;
        var outro = response.data.graphContent && response.data.graphContent.outro;
        var insight = response.data.graphContent && response.data.graphContent.insight;
        // var graphData = response.data.graphContent && response.data.graphContent.graph_vals;
        setSuggestion((prevState) => ({...prevState, 'options': response.data.suggestions_arr}));
        setLoading(false);
        // const responseData = response.data.message;   // msg
        // const stringifiedResponse = JSON.stringify(response.data).replace(/\\/g, '\\\\').replace(/"/g, '\\"');
        // const stringifiedResponse = JSON.stringify(response.data);
        // console.log('stringifiedResponse', stringifiedResponse);
        // console.log('typeof stringifiedResponse', typeof stringifiedResponse);
        // console.log('parsed stringifiedResponse', JSON.parse(stringifiedResponse));
        handleNewMessage(response.data, 'assistant');
      }
      catch(error) {
        setLoading(false);
        setChatLog(prevChatLog => [
            ...prevChatLog.slice(0, -1),
            { 
                sender: 'assistant', 
                message: {
                  message: 'Unexpected error occured'
                },
            }
        ]);
        console.log('Error:', error);
      }
  })
  .catch((error) => {
      console.log('catch error:', error);   // IMP:: this is replacing user's msg with error
      setLoading(false);
      setChatLog(prevChatLog => [
        ...prevChatLog.slice(0, -1),
        { 
            sender: 'assistant', 
            message: {
              message: 'Unexpected error occured'
            },
        }
      ]);
  });

  // Imports logic (older)
  //     try{
  //         var msg = response.data.message;
  //         var outro = response.data.graphContent && response.data.graphContent.outro;
  //         var insight = response.data.graphContent && response.data.graphContent.insight;
  //         // var graphData = response.data.graphContent && response.data.graphContent.graph_vals;

  //         setSuggestion((prevState) => ({...prevState, 'options': response.data.suggestions_arr}));
  //         setLoading(false);
  //         // render component based on filePath
  //         if(response.data.task) {
  //             const taskname = response.data.task.taskname;
  //             const taskArguments  = response.data.task.arguments;

  //             // Construct the filePath
  //             let filePath = taskname;
  //             for (const argumentKey in taskArguments) {
  //                 if (taskArguments.hasOwnProperty(argumentKey)) {
  //                     filePath += `/${taskArguments[argumentKey]}`;
  //                 }
  //             }

  //             // fetch component based on filePath
  //             // const Component = componentMap[filePath.toLowerCase()];
  //             // console.log('filePath', filePath);
  //             if(componentMap[filePath.toLowerCase()]) {
  //                 setChatLog(prevChatLog => [
  //                     ...prevChatLog.slice(0, -1),
  //                     { 
  //                         user: 'assistant', 
  //                         message: filePath.toLowerCase(),
  //                         // id: uuidv4()
  //                     }
  //                 ]);
  //             }
  //         } else {
  //             // msg = "Sure! Here is the Profit & Loss statement for this year:\n\nIncome:\n- Sales: $18,459.95\n- Burner Package & Parts Sales: $14,200.00\n\nTotal Income: $33,002.45\n\nCost of Goods Sold:\n- Cost of Goods Sold: $960.22\n\nTotal Cost of Goods Sold: $960.22\n\nGross Profit: $32,042.23\n\nExpenses:\n- Commissions Expense: $1,700.00\n- Stripe Fee: $1.00\n- Round Off (Default): -$0.05\n- Other Expenses: $0.00\n- Miscellaneous Other: $50,000.00\n- Software Expense (Perpetual < $1500): $1,000.00\n\nTotal Expenses: $52,700.00\n\nNet Profit: -$20,657.77\n\nPlease note that the Profit & Loss statement is based on the data provided and may vary based on any additional transactions or adjustments"
  //             // const md = new MarkdownIt();
  //             // const htmlMsg = md.render(msg);
  //             // const htmlMsgOutro = outro && md.render(outro);
  //             // const htmlMsgInsight = insight && md.render(insight);
              
  //             // setChatLog(prevChatLog => {
  //                 // const prevChat = prevChatLog[prevChatLog.length-1]
  //                 // return ([
  //                 //     ...prevChatLog.slice(0, -1),
  //                 //     { 
  //                 //         ...prevChat,
  //                         // message: <div className={`markdown-msg`} dangerouslySetInnerHTML={{ __html: htmlMsg }} />,
  //                         // suggestions: response.data.suggestions_arr
  //                         // user: 'assistant', 
  //                         // message: prevChat.message,
  //                         // graph: {
  //                         //     ...prevChat.graph,
  //                         //     outro: <div  dangerouslySetInnerHTML={{ __html: htmlMsg }} />
  //                         // },
  //                         // id: uuidv4()
  //                 //     }
  //                 // ])
  //             // });

  //             // add 'assistant' message in db
  //             // console.log('final api response.data:', response.data);
  //             // handleNewMessage(JSON.stringify(response.data).replace(/\\/g, '\\\\').replace(/"/g, '\\"'), 'assistant');
  //             // handleNewMessage(JSON.stringify(response.data), 'assistant');
  //             handleNewMessage(msg, 'assistant');
  //         }
  //     }
  //     catch(error) {
  //         setLoading(false);
  //         setChatLog(prevChatLog => [
  //             // ...prevChatLog.slice(0, -1),
  //             { 
  //                 sender: 'assistant', 
  //                 message: 'Unexpected error occured',
  //                 // id: uuidv4()
  //             }
  //         ]);
  //         console.log('Error:', error);
  //     }
  // })
  // .catch((error) => {
  //     console.log('catch error:', error);
  //     setLoading(false);
  //     setChatLog(prevChatLog => [
  //         ...prevChatLog.slice(0, -1),
  //         { 
  //             sender: 'assistant', 
  //             message: 'Unexpected error occured',
  //             // id: uuidv4()
  //         }

  //         // ...prevChatLog,
  //         // {
  //         //     "message": "Unexpected error occured!!",
  //         //     "messageID": "6510a47843736b67ac91c574",
  //         //     "sender": "assistant",
  //         //     "sent_at": "2023-09-24T21:04:56.416000",
  //         //     "threadID": selectedThreadId
  //         // }
  //     ]);
  // });
}

export async function updateThreadName(threadId, input, setThreads, setSelectedThread) {
  try {
      const response = await axios.put(`${ApiConstants.URL.CHAT.BASE_CHAT}/chat/threads/${threadId}`, {
          firstMessage: input
      });
      
      if (response.status === 200) {
          const newThreadName = response.data.result.threadName.replace(/"/g, '');
          console.log(response.data);
          setThreads((prevState) => {
            let threadWithNewName = [threadId, newThreadName, prevState && prevState[0] && prevState[0][2]];
            return (
              [
                threadWithNewName, 
                ...prevState.slice(1)
              ]
            )
          });
          setSelectedThread((prevState) => ({...prevState, name: newThreadName}));
          // console.log(response)
          

      } else {
          console.error(`create new thread request failed with status: ${response.status}`);
      }
  } catch(err) {
      console.error('error while updating thread name', err);
  }
}