// /** deskera AreadChart */
// import { useState, useEffect } from 'react';
// import { AreaChart, LineChart } from "deskera-ui-library";


// export default function BarGraph({ actual_data }) {
//   const data = [
//     {
//         "title": "10-06-2023",
//         "value": 1449.3110025762,
//         "color": "blue"
//     },
//     {
//         "title": "11-06-2023",
//         "value": 1450.2273596555,
//         "color": "gray"
//     },
//     {
//         "title": "01-07-2023",
//         "value": 1468.5545012424,
//         "color": "red"
//     },
//     {
//         "title": "02-07-2023",
//         "value": 1469.4708583217,
//         "color": "magenta"
//     },
//     {
//         "title": "03-07-2023",
//         "value": 1470.3872154011,
//         "color": "green"
//     },
// ]

//   return (
//     <AreaChart data={data} height={170} showLegend={true} className="" />
//   )
// }


/* deskera ui library */
import { useState, useEffect } from 'react';
import { AreaChart, LineChart } from "deskera-ui-library";

export default function BarGraph({ data, toPredict }) {
  
  console.log('(barrrrrr data):', data);
  console.log('(barrrrrr toPredict):', toPredict);
  const [graphData, setGraphData] = useState([]);

  useEffect(() => {
    const graphDataArr = data.map((item) => (
      {
        color: item.color,
        title: item.title,
        value: item.value,
      }
    ))
    setGraphData(graphDataArr)
  }, [data])
    
  return (
    <AreaChart 
      data= { 
        [ 
          {
            title: toPredict || '',    // change it to whatever graph it is
            color: 'darkgreen',
            points: graphData,
          } 
        ] 
      }
      thresholdData={ {
        title: "Threshold",
        value: 0,   // set threshold
        color: 'gray',
        bgColorAbove: 'green',
        bgColorBelow: 'yellow',
        opacity: 0.3
      }} 
      height={200} 
      showLegend={true} 
      className=""
    />
  )
}

// /**Older graph */
// import React, { useState, useEffect } from 'react';
// import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from "recharts"

// export default function BarGraph({ data }) {

//   const [graphData, setGraphData] = useState({});
//   const [pageWidth, setPageWidth] = useState(window.innerWidth);

  // useEffect(() => {
  //   const graphDataArr = data.map((item) => ({
  //     name: item.title,
  //     total: item.value
  //   }))
  //   setGraphData(graphDataArr);
  // }, [data])
//   // useEffect(() => {
//   //   const rangeSize = 1; // Change this to whatever number you want
    
//   //   let graphDataArr = [];
    
//   //   for (let i=0; i<data.length; i+=rangeSize) {
//   //     let rangeItems = data.slice(i, i+rangeSize);
      
//   //     let avgValue = rangeItems.reduce((sum, item) => sum + item.value, 0) / rangeItems.length;
      
//   //     graphDataArr.push({
//   //       color: 'blue',
//   //       title: rangeItems[0].title + " - " + rangeItems[rangeItems.length-1].title,
//   //       value: avgValue,
//   //     });
      
//   //   }
    
//   //   setGraphData(graphDataArr);
//   // }, [data])

//   // Update pageWidth when the window is resized
//     const updatePageWidth = () => {
//         setPageWidth(window.innerWidth);
//     };

//     useEffect(() => {
//         window.addEventListener('resize', updatePageWidth);
//         return () => {
//             window.removeEventListener('resize', updatePageWidth);
//         };
//     }, []);
  
//   return (
//     <small className='' style={{overflow: 'auto', padding: '6rem'}}>
//       <small><h1>Bar Graph</h1></small>
//       {/* <ResponsiveContainer width={pageWidth>=1300 ? 550 : 920} height={245}> */}
//       <ResponsiveContainer width={'100%'} height={245}>
//         <BarChart data={graphData}>
//           <XAxis
//             dataKey="name"
//             stroke="#888888"
//             fontSize={8}
//             tickLine={true}
//             interval={5}
//           />
//           <YAxis
//             stroke="#888888"
//             fontSize={12}
//             tickLine={true}
//             axisLine={true}
//             tickFormatter={(value) => `$${value}`}
//           />
//           <Bar dataKey="total" fill="#B7CBFF" radius={[10, 10, 0, 0]} isAnimationActive={false}/>
//         </BarChart>
//       </ResponsiveContainer>
//     </small>
//   )
// }


