import NewChatBtn from './NewChatBtn';
import UserInfoBtn from './UserInfoBtn';

function ChatSidebarFooter({ loading, setSelectedThread, setChatLog, darkMode,  }) {
  return (
    <div className={`chat-sidebar-footer${darkMode ? '-dark' : ''} d-flex column w-100 p-s`}>
      <NewChatBtn loading={loading} setSelectedThread={setSelectedThread} setChatLog={setChatLog} darkMode={darkMode} />
      <UserInfoBtn darkMode={darkMode} />
    </div>
  )
}

export default ChatSidebarFooter
