import React from 'react'
import { DKSpinner, DKIcon, DKInput, DKIcons, DKSearchBar } from 'deskera-ui-library';
// import ic_profile_pic from "../../assets/menu/ic_profile_pic.png";
import userAvatarLight  from "../../assets/chatbot/user-avatar_light.svg";
import userAvatarDark  from "../../assets/chatbot/user-avatar_dark.svg";
import aiAvatar from "../../assets/chatbot/ai-avatar.svg";
import MarkdownIt from 'markdown-it';
import renderGraph from '../../utils/GraphUtils';
import { render } from '@testing-library/react';

export default function ChatMessage({ message, darkMode }) {
    // NOTE:: message.message is the answer string
    // message.message is string
    // message.message.graphContent
    let markdownMessage = null;
    if(message.sender==='assistant' && typeof message.message.message==='string') {
        markdownMessage = new MarkdownIt().render(message.message.message);
    }
    let renderedGraph = null;
    if(message && message.message && message.message.graphContent && message.message.graphContent.graph_vals && Array.isArray(message.message.graphContent.graph_vals)) {
        renderedGraph = renderGraph({graph_vals: message.message.graphContent.graph_vals, type: message.message.graphContent.type, toPredict: message.message.graphContent.toPredict});
    }
    return (
        <>
            {
                message.sender==='user' 
                ? 
                (
                <div className={`user-message-container${darkMode ? '-dark' : ''}`}>
                    <div className='user-avatar'>
                        <img src={darkMode ? userAvatarLight : userAvatarDark} alt="User Avatar" />
                    </div>
                    <div className='user-message ml-l w-80'>
                        <div className={`user-message-intro${darkMode ? '-dark' : ''} border-radius-l p-l`}>{message.message}</div>
                    </div>
                </div>
                )
                :
                (
                <div className={`bot-message-container${darkMode ? '-dark' : ''}`}>
                    <div className='bot-avatar'>
                        <img src={aiAvatar} alt="AI Avatar" />
                    </div>
                    <div className='bot-message ml-l w-80'>
                        <div className={`bot-message-intro${darkMode ? '-dark' : ''} border-radius-l p-l`}>
                            {markdownMessage ? <div className={`markdown-msg`} dangerouslySetInnerHTML={{ __html: markdownMessage }} /> : message.message.message}
                        </div>
                        {
                            message && message.message && message.message.graphContent &&
                            (
                                <div className={`bot-message-graph-insight-container${darkMode ? '-dark' : ''}`}>
                                    <div className={`bot-message-graph${darkMode ? '-dark' : ''} border-radius-l  p-l custom-scroll`}>{renderedGraph ? renderedGraph : message.message.graphContent.graph_vals}</div>
                                    <div className={`bot-message-insight${darkMode ? '-dark' : ''} border-radius-l  p-l`}>{message.message.graphContent.insight}</div>
                                </div>
                            )
                        }
                        {
                            message && message.message && message.message.graphContent && message.message.graphContent.outro && 
                            (
                                <div className={`bot-message-outro${darkMode ? '-dark' : ''} border-radius-l  p-l`}>{message.message.graphContent.outro}</div>
                            )
                        }
                    </div>
                </div>
                )
            }
            {/* <h1>temp msg</h1> */}
        </>
    )
}