import { useEffect, useState } from 'react';
// import chats from '../../assets/chats';
import backArrowDark from "../../assets/chatbot/back-arrow_dark.svg";
import backArrowLight from "../../assets/chatbot/back-arrow_light.svg";
import ChatSidebarFooter from './ChatSidebarFooter';
import messageDarkBlue from '../../assets/chatbot/message_dark_blue.svg';
import messageLightBlue from '../../assets/chatbot/message_light_blue.svg';
import messageWhite from '../../assets/chatbot/message_white.svg';
import messageGray from '../../assets/chatbot/message_gray.svg';
import { DKSpinner } from 'deskera-ui-library';

import { getMessagesByThreadId } from '../../utils/ChatUtils';

export default function ChatSidebar({ setSuggestion, loading, selectedThread, setSelectedThread, setChatLog, threads, darkMode, pageWidth, sidebarIsActive, setSidebarIsActive }) {
  // console.log(`Date(): ${new Date()}`);
  // console.log(threads);

  async function handleGetMessages(threadId, threadName) {
    if(!loading) {
      // select current thread
      setSelectedThread({id: threadId, name: threadName});
      // setSuggestion({selected: false, value: '', options: []});

      try {
        const messages = await getMessagesByThreadId(threadId);
        if (messages !== null) {
          setChatLog(messages);
        } else {
          console.error('Cannot retrieve messages');
        }
      } catch (error) {
        console.error('An error occurred while fetching messages:', error);
      }
    } 
  }

  return (
    <div
      className={`chat-sidebar${darkMode ? '-dark' : ''} ${sidebarIsActive ? 'active-sidebar' : 'inactive-sidebar'} d-flex column h-70`} 
    >
      <div className='chat-sidebar-header ml-xl fs-xl fw-b pb-l'>Deskera <span className='fw-r'>AI</span></div>
      <img className={`chat-sidebar-back-btn${darkMode ? '-dark' : ''} cursor-pointer`} onClick={() => setSidebarIsActive(false)} src={darkMode ? backArrowLight : backArrowDark} alt="back-arrow" />
      <div className={`chat-sidebar-header-separator${darkMode ? '-dark' : ''} w-100`}></div>
        
      {/* <small className={`chat-date${darkMode ? '-dark' : ''} ml-l mt-l`} style={{color: '#9D9D9D', fontWeight: '400'}}>TODAY</small> */}
      <div className={`chat-sidebar-list${darkMode ? '-dark' : ''} w-100 pt-m custom-scroll`} >
        {
          threads.length>0
            ?
            threads.map((thread, index) => {
            // console.log(thread);
            return (
              <div className={`chat-sidebar-item${darkMode ? '-dark' : ''}${thread[0]===selectedThread?.id ? '-selected' : ''} d-flex pl-l align-items-center ${loading ? 'cursor-not-allowed' : 'cursor-pointer'}`} onClick={() => handleGetMessages(thread[0], thread[1])}>
                <span className='chat-icon'>
                  {/* {chat.icon} */}
                  {
                    darkMode
                    ?
                    thread[0]===selectedThread?.id ? <img src={messageWhite} alt="message-icon" /> : <img src={messageGray} alt="message-icon" />
                    :
                    thread[0]===selectedThread?.id ? <img src={messageDarkBlue} alt="message-icon" /> : <img src={messageLightBlue} alt="message-icon" />
                  }
                </span>
                <span className='chat-name cursor-default ellipsis'>
                  {thread[1]}
                </span>
            </div>
            )
          })
          :
            <DKSpinner
              className="mr-l"
              iconClassName="ic-s"
              title=""
            />
          }
      </div>
      <ChatSidebarFooter loading={loading} setSelectedThread={setSelectedThread} setChatLog={setChatLog} darkMode={darkMode} />
    </div>
  )
}
